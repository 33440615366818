<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.barcode"
            :placeholder="$t(`请输入商品条码`)"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.bizOrderSn"
            :placeholder="$t(`请输入业务单据号`)"
          />
        </a-col>
        <a-col :span="6">
          <a-select
            style="width: 100%"
            allow-clear
            v-model.trim="formData.inventoryStatus"
            :placeholder="$t(`请选择库存类型`)"
          >
            <a-select-option v-for="(item, key) in inventoryStatusMapping" :value="key" :key="key">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <a-select
            style="width: 100%"
            allow-clear
            v-model.trim="formData.transactionType"
            :placeholder="$t(`请选择出入库类型`)"
          >
            <a-select-option :value="key" v-for="(item, key) in transactionTypeMapping" :key="key">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="mt10" :gutter="16">
        <a-col :span="6">
          <!-- 仓库名称选项 -->
          <SearchHourse @selected="selectedWarehouse" />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            :startTime="formData.operateTimeStart"
            :endTime="formData.operateTimeEnd"
            format="YYYY-MM-DD"
            @get_date="onChangeDate"
          />
        </a-col>
        <a-col :span="12">
          <a-button type="primary" icon="search" @click="getInventoryFlowList(1)" class="btnBgc">
            {{ $t(`查询`) }}
          </a-button>
          <a-button type="default" icon="download" @click="exportHandle" class="btnBgc">
            {{ $t(`导出`) }}
          </a-button>
        </a-col>
      </a-row>

      <div class="table-content mt10">
        <div class="table-body">
          <a-table
            :scroll="{ x: 1500, y: tableHeight }"
            :columns="tableColumns"
            :data-source="tableData"
            :rowKey="(record, index) => index"
            :pagination="false"
            bordered
          >
            <template slot="goodsInfo" slot-scope="text, record">
              <div class="flex">
                <img
                  v-if="record.skuImage"
                  :src="record.skuImage"
                  width="60"
                  height="60"
                  class="mr10"
                  style="display: block; object-fit: cover"
                />
                <div style="text-align: left">
                  <div>{{ $t('名称') }}:{{ record.skuName }}</div>
                  <div>{{ $t('条码') }}:{{ record.barcode }}</div>
                  <div>{{ $t('编码') }}:{{ record.skuCode }}</div>
                </div>
              </div>
            </template>
            <template slot="transactionQuantityValue" slot-scope="text, record">
              <MulUnitShow
                :list="record.unitList"
                :amount="record.transactionQuantity"
                :amountString="record.transactionQuantityValue"
              />
            </template>
            <template slot="leftQuantity" slot-scope="text, record">
              <MulUnitShow :list="record.unitList" :amount="text" />
            </template>
            <span slot="leftQuantityTitle">
              <a-popover>
                <template slot="content">
                  <span>{{ $t('结余数量解释') }}</span>
                </template>
                <span>
                  {{ $t('结余数量') }}
                  <a-icon style="color: #1890ff" type="question-circle"></a-icon>
                </span>
              </a-popover>
            </span>
          </a-table>
        </div>
        <div class="table-footer mt6">
          <Pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import http from '@common/http'
import api from '@common/api'
import Pagination from '@component/pagination'
import SearchHourse from '@/component/searchHourse.vue'
import DateRangePicker from '@/component/dateRangePicker.vue'
import MulUnitShow from '@/component/mulUnitShow.vue'
import { getDateStr } from '@common/getDateStr'
export default {
  name: 'InventoryFlow',
  activated() {
    this.getInventoryFlowList()
  },
  components: {
    Pagination,
    SearchHourse,
    DateRangePicker,
    MulUnitShow,
  },
  data() {
    return {
      formData: {
        barcode: undefined,
        bizOrderSn: undefined,
        warehouseCode: undefined,
        status: undefined,
        operateTimeStart: undefined,
        operateTimeEnd: undefined,
        inventoryStatus: undefined,
        transactionType: undefined,
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
    }
  },
  mounted() {
    this.formData.operateTimeStart = `${getDateStr(-90, false)} 00:00:00`
    this.formData.operateTimeEnd = `${getDateStr(1, false)} 23:59:59`
  },
  computed: {
    tableColumns() {
      return [
        {
          title: this.$t('商品信息'),
          align: 'center',
          scopedSlots: { customRender: 'goodsInfo' },
          width: 300,
        },
        {
          title: this.$t('规格值'),
          align: 'center',
          dataIndex: 'skuStyleList',
          customRender: (text) => text?.join(', '),
        },
        {
          title: this.$t('类目'),
          align: 'center',
          dataIndex: 'category',
        },
        {
          title: this.$t('业务单据号'),
          align: 'center',
          dataIndex: 'bizOrderSn',
        },
        {
          title: this.$t('仓库'),
          align: 'center',
          dataIndex: 'warehouseName',
        },
        {
          title: this.$t('出入库类型'),
          align: 'center',
          dataIndex: 'transactionType',
          customRender: (text) => this.transactionTypeMapping[text],
        },
        {
          title: this.$t('库存类型'),
          align: 'center',
          dataIndex: 'inventoryStatus',
          customRender: (text) => this.inventoryStatusMapping[text],
        },
        {
          title: this.$t('数量'),
          align: 'center',
          dataIndex: 'transactionQuantityValue',
          scopedSlots: { customRender: 'transactionQuantityValue' },
        },
        {
          // title: this.$t('结余数量'),
          slots: { title: 'leftQuantityTitle' },
          align: 'center',
          dataIndex: 'leftQuantity',
          scopedSlots: { customRender: 'leftQuantity' },
        },
        {
          title: this.$t('操作时间'),
          align: 'center',
          dataIndex: 'operateTime',
        },
      ]
    },
    tableHeight() {
      return (window.innerHeight - 330) * this.$store.state.rate
    },
    transactionTypeMapping() {
      return {
        PURCHASE_IN: this.$t('采购入库'),
        SALES_IN: this.$t('销退入库'),
        TRANSFER_IN: this.$t('调拨入库'),
        VARIANCE_IN: this.$t('调整入库(盘盈)'),
        TRANSFORM_IN: this.$t('转换入库'),
        PROCESS_IN: this.$t('加工入库'),
        PURCHASE_OUT: this.$t('采购出库'),
        SALES_OUT: this.$t('销售出库'),
        TRANSFER_OUT: this.$t('调拨出库'),
        VARIANCE_OUT: this.$t('调整出库(盘亏)'),
        TRANSFORM_OUT: this.$t('转换出库'),
        PROCESS_OUT: this.$t('加工出库'),
        CANCEL_PROCESS: this.$t('生产返库'),
        SAMPLE_OUT: this.$t('样品出库'),
        SCRAP_OUT: this.$t('报废出库'),
        CLAIM_OUT: this.$t('领用出库'),
      }
    },
    inventoryStatusMapping() {
      return {
        CERTIFIED_PRODUCTS: this.$t('正品'),
        DEFECTIVE_PRODUCTS: this.$t('残次品'),
        FROZEN_PRODUCTS: this.$t('冻结库存'),
        SAFETY_PRODUCTS: this.$t('安全库存'),
      }
    },
  },
  methods: {
    // 时间范围查询
    onChangeDate(data) {
      if (data.start_time) {
        this.formData.operateTimeStart = `${data.start_time} 00:00:00`
        this.formData.operateTimeEnd = `${data.end_time} 23:59:59`
      } else {
        this.formData.operateTimeStart = undefined
        this.formData.operateTimeEnd = undefined
      }
    },
    // 选择仓库
    selectedWarehouse(data) {
      this.formData.warehouseCode = data.warehouseCode
    },
    // 获取盘点单列表
    getInventoryFlowList(first_page = false) {
      first_page && (this.pageNo = 1)
      this.$nextTick(() => {
        let data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          bizOrderSn: this.formData.bizOrderSn,
          barcode: this.formData.barcode,
          operateTimeStart: this.formData.operateTimeStart,
          operateTimeEnd: this.formData.operateTimeEnd,
          transactionType: this.formData.transactionType,
          inventoryStatus: this.formData.inventoryStatus,
          warehouseCode: this.formData.warehouseCode,
          buid: this.$store.getters.partyId,
        }
        http({
          type: 'post',
          url: api.querySkuStockTransactionList,
          hasLoading: true,
          data,
          success: (res) => {
            this.tableData = res.result.skuStockTransactionList
            this.total = res.result.total
          },
        })
      })
    },
    // 分页功能
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.getInventoryFlowList()
    },
    exportHandle() {
      let currentShop = JSON.parse(localStorage.getItem('currentShop'))
      let mergeCode = currentShop?.uniqueCode
      let appCode = localStorage.getItem('appCode')
      http({
        url: api.taskCreate,
        data: {
          bookCode: 'exportInventoryTransaction',
          mergeCode,
          merchantShopName: currentShop.name,
          appCode,
          params: {
            buId: this.$store.getters.partyId,
            merchantCode: localStorage.getItem('merchantCode'),
            ...this.formData,
          },
        },
        type: 'post',
        hasLoading: false,
        success: (res) => {
          // console.log(res)
          if (res.result.task_id) {
            this.$message.success(this.$t('创建导出任务成功,请前往【数据中心/下载中心】下载'))
          } else {
            this.$message.error(this.$t('创建导出任务失败'))
          }
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sku-code {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 控制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
