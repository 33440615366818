<template>
  <div class="create-batch-check-order-job">
    <div class="content titleFormBox flex-ct" style="font-weight: 900">
      <a-tabs default-active-key="Verification" @tabClick="linkTo">
        <a-tab-pane key="Verification" :tab="$t(`盘点信息`)" force-render></a-tab-pane>
        <a-tab-pane key="Product" :tab="$t(`商品信息`)" force-render></a-tab-pane>
      </a-tabs>
      <a-button
        v-if="formData.status === 'CONFIRMED'"
        type="primary"
        style="margin-left: auto"
        @click="showPriceAdjustment"
      >
        {{ $t('盘盈价格调整') }}
      </a-button>
    </div>
    <div class="content-overflow">
      <section class="left-div fixContent mt10">
        <a-form-model ref="ruleForm" class="infoTop" :model="formData" id="Verification">
          <div class="infoTitle">
            <div class="flex-ct">
              <i class="leftLine"></i>
              <span>{{ $t('盘点信息') }}</span>
              <span>
                <a
                  style="margin-left: 5px; font-size: 14px"
                  href="javascript:;"
                  v-if="isEdit && !isShow && formData.status === 'WAIT_CONFIRM'"
                  @click.prevent="isShow = !isShow"
                >
                  <a-icon type="edit" />
                </a>
                <a
                  style="margin: 0 5px; font-size: 14px"
                  href="javascript:;"
                  v-if="isEdit && isShow && formData.status === 'WAIT_CONFIRM'"
                  @click.prevent="createForm"
                >
                  <a-icon type="check-circle" style="color: rgb(82, 196, 26)" />
                </a>
                <a
                  style="font-size: 14px"
                  href="javascript:;"
                  v-if="isEdit && isShow && formData.status === 'WAIT_CONFIRM'"
                  @click.prevent="cancelEdit"
                >
                  <a-icon type="close-circle" />
                </a>
              </span>
            </div>
            <div
              v-if="!isShow && !isInfoShow && formData.status && formData.status == 'WAIT_CONFIRM'"
            >
              <a-button v-if="formData.status === 'WAIT_CONFIRM'" type="primary" @click="onConfirm">
                {{ $t(`确认`) }}
              </a-button>
              <a-button
                style="margin-left: 15px"
                v-if="formData.status && formData.status == 'WAIT_CONFIRM'"
                type="danger"
                @click="onVoidOrder"
              >
                {{ $t(`作废`) }}
              </a-button>
            </div>
          </div>
          <!-- 盘点信息 -->
          <a-row class="mb10" v-if="isEdit">
            <span>{{ $t('盘点单号') }}:</span>
            <span style="font-size: 15px">{{ formData.verificationSn }}</span>
            <a-tag v-if="isEdit" style="font-size: 12px; font-weight: normal; margin-left: 10px">
              {{ statusFilter[formData.status] }}
            </a-tag>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="8">
              <a-form-model-item :label="$t(`所属仓库`)" :required="true">
                <a-select
                  allowClear
                  :placeholder="$t(`请选择`) + $t('所属仓库')"
                  style="width: 100%"
                  v-model="formData.warehouseCode"
                  :disabled="isEdit && (!isEdit || !isShow)"
                  @change="selectWarehouseCode"
                >
                  <a-select-option
                    v-for="item in warehouseList"
                    :key="item.code"
                    :value="item.warehouseCode"
                  >
                    {{ item.warehouseName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item :label="$t(`盘点名称`)">
                <a-input
                  :disabled="isEdit && (!isEdit || !isShow)"
                  v-model="formData.verificationName"
                  :placeholder="$t(`盘点名称`)"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item :label="$t(`盘点时间`)" :required="true">
                <a-date-picker
                  id="orderTime"
                  style="background-color: #ffffff; width: 100%"
                  :init-date-time="formData.verifiedTime"
                  v-model="formData.verifiedTime"
                  :disabled="isEdit && (!isEdit || !isShow)"
                  @change="selectDate"
                  :placeholder="$t(`请选择`) + $t(`盘点时间`)"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="16">
              <a-form-model-item :label="$t(`备注`)">
                <a-input
                  :disabled="isEdit && (!isEdit || !isShow)"
                  v-model="formData.note"
                  :placeholder="$t(`备注`)"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-form-model id="Product" class="infoBottom">
          <div class="infoTitle">
            <div class="flex-ct">
              <i class="leftLine"></i>
              {{ $t('商品信息') }}
              <span>
                <a
                  style="margin-left: 5px; font-size: 14px"
                  href="javascript:;"
                  v-if="isEdit && !isInfoShow && formData.status === 'WAIT_CONFIRM'"
                  @click.prevent="isInfoShow = !isInfoShow"
                >
                  <a-icon type="edit" />
                </a>
                <a
                  style="margin: 0 5px; font-size: 14px"
                  href="javascript:;"
                  v-if="isEdit && isInfoShow && formData.status === 'WAIT_CONFIRM'"
                  @click.prevent="createInfoForm"
                >
                  <a-icon type="check-circle" style="color: rgb(82, 196, 26)" />
                </a>
                <a
                  style="font-size: 14px"
                  href="javascript:;"
                  v-if="isEdit && isInfoShow && formData.status === 'WAIT_CONFIRM'"
                  @click.prevent="cancelInfoEdit"
                >
                  <a-icon type="close-circle" />
                </a>
              </span>
            </div>
          </div>
          <a-row
            v-if="!editShow || (editShow && isInfoShow)"
            style="display: flex; position: relative"
          >
            <a-col style="flex: 1">
              <SearchSku :style="{ width: '700px' }" @selected="handleChangeSku" />
              <a-button type="primary" style="margin-left: 15px" @click="addGoodsToTable">
                <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
                {{ $t('添加') }}
              </a-button>
            </a-col>
            <a-col>
              <a-button type="primary" @click="showImportData">
                {{ $t('导入盘点数据') }}
              </a-button>
            </a-col>
          </a-row>
          <a-row>
            <div class="table-wrapper" style="margin-top: 10px">
              <!--每个列的宽度必须比列名总长度大才能使表格所有列对齐，留一个列不设置宽度  :rowSelection="{selectedRowKeys: selectedRowKeys}"-->
              <a-table
                :columns="columns"
                :dataSource="tableData"
                bordered
                :loading="tableLoading"
                :pagination="false"
                :scroll="{ x: 1500 }"
                :rowKey="
                  (record, index) => {
                    return index
                  }
                "
              >
                <template slot="ProductInfo" slot-scope="text, record">
                  <div>{{ $t('名称') }}:{{ record.productName }}</div>
                  <div>{{ $t('条码') }}:{{ record.barcode }}</div>
                </template>
                <template v-for="col in customRenderList" v-slot:[col]="text, record, index">
                  <div :key="col">
                    <!-- includes 用来判断当前的输入类型 -->
                    <div v-if="isInfoShow || record.editable">
                      <a-input-number
                        :min="0"
                        :read-only="readonlyArr.includes(col)"
                        :placeholder="$t(`PleaseEnter`)"
                        :formatter="Formatter"
                        :parser="Formatter"
                        v-if="col === 'verifiedQuantity'"
                        :value="text"
                        @change="(e) => handleChange(e, record.barcode, col, index)"
                      />
                      <a-input
                        :read-only="readonlyArr.includes(col)"
                        :placeholder="$t(`PleaseEnter`)"
                        :maxLength="10"
                        v-else-if="inputScopedSlots.includes(col)"
                        :value="text"
                        @change="(e) => handleChange(e.target.value, record.barcode, col, index)"
                      />
                      <a-select
                        @change="(e) => onChangeSelect(e, record.barcode, col, index)"
                        v-else-if="selectScopedSlots.includes(col)"
                        :value="text"
                        style="width: 120px"
                      >
                        <a-select-option value="CERTIFIED_PRODUCTS">
                          {{ $t(`正品`) }}
                        </a-select-option>
                        <a-select-option value="DEFECTIVE_PRODUCTS">
                          {{ $t(`残次品`) }}
                        </a-select-option>
                        <a-select-option value="FROZEN_PRODUCTS">
                          {{ $t(`冻结库存`) }}
                        </a-select-option>
                      </a-select>
                      <a v-else-if="col === 'delect'" @click="onDelect(record.barcode, index)">
                        <a-icon type="delete" style="color: red" />
                      </a>
                    </div>
                    <div v-else-if="col === 'inventoryStatus'">
                      {{ filterInventoryStatus[text] }}
                    </div>
                    <div v-else>{{ text }}</div>
                  </div>
                </template>
              </a-table>
            </div>
          </a-row>
        </a-form-model>
      </section>
    </div>
    <div class="content" style="min-height: 0">
      <a-row type="flex" justify="center">
        <a-col v-if="formData.status == null">
          <a-row type="flex" justify="start" :gutter="16">
            <a-button type="default" @click="backForm" style="margin: 0 15px">
              {{ $t(`取消`) }}
            </a-button>
            <a-button v-if="formData.status !== 'FINISH'" type="primary" @click="onSubmit">
              {{ $t(`保存`) }}
            </a-button>
          </a-row>
        </a-col>
        <a-col v-else>
          <a-button type="default" @click="backForm" style="margin: 0 15px">
            {{ $t(`返回`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <!-- 导入盘点数据 -->
    <a-modal
      class="importModal"
      v-model="isImportData"
      :title="modalInfo.modalTitle"
      :width="500"
      :footer="null"
      style="padding: 10px"
    >
      <div class="upload-content">
        <a-upload-dragger
          name="file"
          :headers="{ 'D-SESSION-ID': sessionId }"
          @change="importData"
          :action="actionUrl"
          :data="{
            warehouseCode:
              this.formData.warehouseCode == undefined ? '' : this.formData.warehouseCode,
            buId: buId,
          }"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" />
          </p>
          <p class="ant-upload-hint">{{ $t('DragUpload') }}</p>
        </a-upload-dragger>
        <a
          v-if="modalInfo.xlsDownloadUrl"
          class="text-info mt10"
          download
          :href="modalInfo.xlsDownloadUrl"
        >
          {{ modalInfo.modalTitle }} {{ $t('Template') }}.xls
        </a>
      </div>
    </a-modal>
    <!-- 修改盘盈价格 -->
    <a-modal
      class="importModal"
      v-model="priceAdjustmentVisible"
      :title="$t('修改盘盈价格')"
      :width="800"
      :confirmLoading="priceAdjustmentLoading"
      @ok="updateInventorySurplusPrice"
    >
      <div class="modal-content">
        <a-table
          :columns="priceAdjustmentColumns"
          :data-source="priceAdjustmentData"
          :pagination="false"
        >
          <span slot="productName" slot-scope="text, record">
            <div v-if="record.productName">{{ $t('名称') }}:{{ record.productName }}</div>
            <div v-if="record.barcode">{{ $t('条码') }}:{{ record.barcode }}</div>
          </span>
          <template slot="unitPrice" slot-scope="text, record">
            <a-input-number style="width: 120px" v-model="record.unitPrice" :min="0" />
            {{ $t(record.unitCode) }}
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import http from '@common/http'
import api from '@common/api'
import { mapState } from 'vuex'
import { getCookie } from '@/common/cookie'
import formatter from '@/common/formatter'
import SearchSku from '@/component/searchSku'
import deepClone from '@/common/deepClone'
export default {
  name: 'addVerificationList',
  components: { SearchSku },
  data() {
    return {
      sessionId: '',
      isImportData: false,
      visible: false,
      editShow: false,
      isEdit: false,
      isShow: false,
      isInfoShow: false,
      inventoryVerificationId: '',
      orderActions: [],
      current: '',
      columns: [
        {
          title: this.$t('商品名称'),
          dataIndex: 'productName',
          width: 250,
          scopedSlots: {
            customRender: 'ProductInfo',
          },
        },
        {
          title: this.$t('商品规格'),
          align: 'center',
          dataIndex: 'productStyle',
          width: 120,
        },
        // 库存类型
        {
          title: this.$t('库存类型'),
          align: 'center',
          dataIndex: 'inventoryStatus',
          width: 120,
          scopedSlots: {
            customRender: 'inventoryStatus',
          },
        },
        {
          title: this.$t('系统库存'),
          align: 'center',
          dataIndex: 'currentQuantity',
          width: 120,
        },
        //盘点库存
        {
          title: this.$t('盘点库存'),
          align: 'center',
          dataIndex: 'verifiedQuantity',
          width: 120,
          scopedSlots: {
            customRender: 'verifiedQuantity',
          },
        },
        // 库存差异
        {
          title: this.$t('盘点差异'),
          align: 'center',
          dataIndex: 'diffQuantityContent',
          width: 120,
        },
        {
          title: this.$t('盘点价格'),
          align: 'center',
          dataIndex: 'unitPrice',
          width: 120,
          customRender: (text, record) => (text ? text + this.$t(record.unitCode) : ''),
        },
        // 库存状态
        {
          title: this.$t('库存状态'),
          align: 'center',
          dataIndex: 'inventoryTypeContent',
          width: 120,
        },
        //  备注
        {
          title: this.$t('备注'),
          align: 'center',
          dataIndex: 'note',
          width: 120,
          scopedSlots: {
            customRender: 'note',
          },
        },
        {
          title: this.$t('Operation'),
          align: 'center',
          dataIndex: 'delect',
          width: 120,
          scopedSlots: {
            customRender: 'delect',
          },
        },
      ],
      // 表格数据
      tableData: [],
      // 每一列的插槽名 - 表格行内编辑用
      customRenderList: ['verifiedQuantity', 'note', 'delect', 'inventoryStatus'],
      // 用来匹配插槽中显示input框
      inputScopedSlots: ['note'],
      // 用来匹配插槽中显示select框
      selectScopedSlots: ['inventoryStatus'],
      // 对于某些自动赋值的input框设为 只读
      readonlyArr: [''],
      // 表格loading
      tableLoading: false,
      // 表格选中key
      selectedRowKeys: [],
      provinceList: [],
      formData: {},
      selectedGoodsOrGroup: {},
      selectedGoodsOrGroupArray: [],
      warehouseList: [], //仓库列表
      currentGood: {
        currentQuantity: null,
      },
      modalInfo: {
        modalTitle: this.$t('BatchInventoryAdjustment'),
        modalDesc: '',
        xlsRequestUrl: '',
        xlsDownloadUrl:
          'https://erp-prod.oss-ap-southeast-1.aliyuncs.com/cloud_bird_oss_gRDnXV93qPU2SDCp/INVENTORY/%E6%89%B9%E9%87%8F%E5%BA%93%E5%AD%98%E8%B0%83%E6%95%B4%E6%A8%A1%E6%9D%BF20230224.xlsx', //模板下载链接
      },
      data: [],
      selectOptionsLoading: false,
      pages: '',
      pageNo: 1,
      pageSize: 20,
      Formatter: formatter,
      priceAdjustmentVisible: false,
      priceAdjustmentLoading: false,
      priceAdjustmentData: [],
    }
  },
  mounted() {
    this.sessionId = getCookie('d_session_id')
    this.initData()
    this.getWarehouseList()
  },
  computed: {
    ...mapState(['userSession']),
    buId() {
      return this.$store.state.partyId
    },
    actionUrl() {
      let cpid = this.$store.getters.partyId
      return api.importVerification + '?cpid=' + cpid
    },
    statusFilter() {
      return {
        WAIT_CONFIRM: this.$t(`待确认`),
        CONFIRMED: this.$t(`已确认`),
        CANCEL: this.$t(`已作废`),
      }
    },
    filterInventoryStatus() {
      return {
        CERTIFIED_PRODUCTS: this.$t(`正品`),
        DEFECTIVE_PRODUCTS: this.$t(`残次品`),
        FROZEN_PRODUCTS: this.$t(`冻结库存`),
      }
    },
    priceAdjustmentColumns() {
      return [
        {
          title: this.$t('商品信息'),
          dataIndex: 'productName',
          scopedSlots: {
            customRender: 'productName',
          },
        },
        {
          title: this.$t('盘点价格'),
          dataIndex: 'unitPrice',
          scopedSlots: {
            customRender: 'unitPrice',
          },
        },
      ]
    },
  },
  watch: {
    editShow(newType) {
      let index = this.columns.findIndex((v) => v.dataIndex === 'delect')
      if (newType) {
        if (index > 0) {
          this.columns.splice(index, 1)
        }
        // this.columns.pop();
      } else {
        if (index < 0) {
          this.columns.push({
            title: this.$t(`操作`),
            align: 'center',
            dataIndex: 'delect',
            width: 120,
            scopedSlots: {
              customRender: 'delect',
            },
          })
        }
      }
    },
    isInfoShow(newType) {
      let index = this.columns.findIndex((v) => {
        return v.dataIndex == 'delect'
      })
      if (newType) {
        if (index < 0) {
          this.columns.push({
            title: this.$t(`操作`),
            align: 'center',
            dataIndex: 'delect',
            width: 120,
            scopedSlots: {
              customRender: 'delect',
            },
          })
        }
      } else {
        if (index > 0) {
          this.columns.splice(index, 1)
        }
      }
    },
  },
  methods: {
    async initData() {
      if (this.$route.query.id) {
        this.inventoryVerificationId = this.$route.query.id
        this.editShow = true
        this.isEdit = true
        await this.getDetailInfo()
        this.$forceUpdate()
      }
    },
    async getDetailInfo() {
      let res = await http({
        url: api.inventoryVerificationDetail,
        type: 'post',
        data: {
          inventoryVerificationId: this.inventoryVerificationId,
        },
      })
      this.tableData = res.result.items
      this.formData = res.result
    },
    selectDate(moment, value) {
      this.formData.verifiedTime = `${value} 00:00:00`
    },
    // 查询barcode系统库存
    queryInventory(barcode, inventoryStatus, index) {
      // 将选中的商品信息修改成数组格式
      let barcodeList = []
      barcodeList.push(barcode)
      http({
        url: api.queryInventoryForProduct,
        type: 'post',
        data: {
          warehouseCode: this.formData.warehouseCode,
          barcodeList: barcodeList,
          inventoryStatus: inventoryStatus,
        },
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            let tempData = [...this.tableData]
            tempData[index]['currentQuantity'] = res.result[barcode].realQuantity
            this.tableData = [...tempData]
          }
        },
      })
    },
    // 确认盘点信息的修改
    createForm() {
      http({
        url: api.inventoryVerificationUpdateInfo,
        type: 'post',
        data: {
          inventoryVerificationId: this.formData.inventoryVerificationId,
          verificationName: this.formData.verificationName,
          warehouseCode: this.formData.warehouseCode,
          verifiedTime: this.formData.verifiedTime,
          note: this.formData.note,
        },
        hasLoading: false,
        success: () => {
          this.initData()
          this.isShow = !this.isShow
        },
      })
    },
    // 确认商品信息的修改
    createInfoForm() {
      http({
        url: api.inventoryVerificationUpdateItems,
        type: 'post',
        data: {
          inventoryVerificationId: this.inventoryVerificationId,
          items: this.tableData,
        },
        hasLoading: false,
        success: () => {
          this.initData()
          this.isInfoShow = !this.isInfoShow
        },
      })
    },
    // 取消盘点信息的修改
    cancelEdit() {
      this.initData()
      this.isShow = !this.isShow
    },
    // 取消商品信息的修改
    cancelInfoEdit() {
      this.initData()
      this.isInfoShow = !this.isInfoShow
    },
    handleChangeSku(data) {
      this.currentGood = data
      if (!this.formData.warehouseCode) {
        this.$warning(this.$t('请选择仓库'))
        return
      }
    },
    handleChange(value, barcode, colName, index) {
      // console.log(value, barcode, colName)
      const newData = JSON.parse(JSON.stringify(this.tableData))
      newData[index][colName] = value
      let target = newData[index]
      this.tableData = JSON.parse(JSON.stringify(newData))
      if (colName === 'verifiedQuantity') {
        let num = value * 1 - target.currentQuantity
        if (value > target.currentQuantity) {
          this.tableData[index]['inventoryTypeContent'] = this.$t(`InventorySurplus`)
          this.tableData[index]['diffQuantityContent'] = '+' + num
        } else if (value < target.currentQuantity) {
          this.tableData[index]['inventoryTypeContent'] = this.$t(`InventoryLoss`)
          this.tableData[index]['diffQuantityContent'] = num
        } else {
          this.tableData[index]['inventoryTypeContent'] = this.$t(`正常`)
          this.tableData[index]['diffQuantityContent'] = num
        }
      }
      if (colName === 'note') {
        this.tableData[index]['note'] = value
      }
    },
    // select 框 change
    onChangeSelect($event, rowKey, colName, index) {
      // console.log($event, rowKey, colName, index)
      const newData = JSON.parse(JSON.stringify(this.tableData))
      newData[index][colName] = $event
      this.tableData = JSON.parse(JSON.stringify(newData))
      this.queryInventory(rowKey, $event, index)
    },
    // 点击删除按钮删除当前对应商品信息
    onDelect(barcode, index) {
      const newData = JSON.parse(JSON.stringify(this.tableData))
      newData.splice(index, 1)
      this.tableData = JSON.parse(JSON.stringify(newData))
      this.formData.items = newData
    },
    // 商品信息手动添加
    addGoodsToTable() {
      // 判断有没选择仓库 没有选择仓库是不允许添加商品信息的
      if (this.formData.warehouseCode === undefined) {
        return
      }
      if (this.currentGood.skuName) {
        this.currentGood.productName = this.currentGood.skuName
        this.currentGood.productStyle = this.currentGood.skuStyleList
          .map((item) => item.styleType + ':' + item.styleValue)
          .join(', ')
        this.currentGood.editable = true
        this.tableData.push(this.currentGood)
      }
    },
    showImportData() {
      if (this.formData.warehouseCode == undefined) {
        this.$warning(this.$t('请选择仓库'))
        return
      }
      this.isImportData = true
    },
    // 获取仓库下来列表
    getWarehouseList() {
      http({
        url: api.warehouseList,
        type: 'post',
        data: {},
        success: (res) => {
          this.warehouseList = [...res.result]
        },
      })
    },
    getProvinceList() {
      http({
        url: api.regionList,
        type: 'post',
        data: {
          data: {
            parentId: '',
            regionType: 1,
          },
        },
        success: (res) => {
          if (res.code == 200) {
            this.provinceList = res.data
          }
        },
      })
    },
    // 保存
    onSubmit() {
      if (!this.formData.warehouseCode) {
        this.$warning({
          title: this.$t(`警告`),
          content: this.$t(`请选择仓库`),
        })
        return
      }
      if (!this.formData.verifiedTime) {
        this.$warning({
          title: this.$t(`警告`),
          content: this.$t(`请输入盘点时间`),
        })
        return
      }
      this.tableData.forEach((item) => {
        if (!item.barcode) {
          this.$warning({
            title: this.$t(`警告`),
            content: this.$t(`商品条码不能为空!`),
          })
          throw Error()
        }
        if (!item.verifiedQuantity && item.verifiedQuantity != 0) {
          this.$warning({
            title: this.$t(`警告`),
            content: this.$t(`PleaseFillIn`) + this.$t(`盘点库存`),
          })
          throw Error()
        }
      })
      const data = this.formData
      data.items = this.tableData
      data.inventoryVerificationId = this.inventoryVerificationId
      // console.log(this.formData)
      http({
        url: api.inventoryVerificationCreate,
        data,
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success && res.result.inventoryVerificationId) {
            this.$success({
              title: this.$t(`Success`),
              content: this.$t(`SuccessfullyAdded`),
            })
            this.isInfoShow = false
            this.$router.back()
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t('CreateFailed'),
            })
          }
        },
      })
    },
    // 确认调整库存
    onConfirm() {
      http({
        url: api.inventoryVerificationConfirm,
        data: {
          inventoryVerificationId: this.inventoryVerificationId,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success && res.result.inventoryVerificationId) {
            this.formData.status = 'CONFIRMED'
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t('CreateFailed'),
            })
          }
        },
      })
    },
    // 作废调整单
    onVoidOrder() {
      http({
        url: api.inventoryVerificationCancel,
        data: {
          inventoryVerificationId: this.inventoryVerificationId,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success && res.result.inventoryVerificationId) {
            this.formData.status = 'CANCEL'
            this.getDetailInfo()
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t('CreateFailed'),
            })
          }
        },
      })
    },
    // 取消返回列表页
    backForm() {
      this.$router.back()
    },
    // 导入数据
    importData(info) {
      const status = info.file.status
      if (status === 'done') {
        if (info.file.response.success) {
          // 导入的数据
          let resultArr = info.fileList[info.fileList.length - 1].response.result.items
          // push到表格列表中
          this.tableData.push(...resultArr)
          // let newData = [...this.tableData];
          // this.tableData = [...this.arrayUnique(newData, "barcode")];
          this.formData.items = this.tableData
          this.isInfoShow = true
          this.$success({
            title: this.$t(`Success`),
            content: `${info.file.name} file uploaded successfully.`,
          })
        } else {
          this.$error({
            title: this.$t(`错误`),
            content: `${info.file.response.msg} file upload failed.`,
          })
        }
      } else if (status === 'error') {
        this.$error({
          title: this.$t(`错误`),
          content: `${info.file.name} file upload failed.`,
        })
      }
      this.isImportData = false
    },
    // 数组对象根据barcode去重方法
    arrayUnique(arr, barcode) {
      var hash = {}
      return arr.reduce((acc, cru, index) => {
        if (!hash[cru[barcode]]) {
          hash[cru[barcode]] = { index: index }
          acc.push(cru)
        } else {
          acc.splice(hash[cru[barcode]]['index'], 1, cru)
        }
        return acc
      }, [])
    },
    // 顶部内容导航
    linkTo(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    // 仓库变更需要更新商品信息的系统库存
    selectWarehouseCode() {
      if (this.tableData.length) {
        let barcodeArr = this.tableData.map((x) => {
          return x.barcode
        })
        http({
          url: api.queryInventoryForProduct,
          type: 'post',
          data: {
            warehouseCode: this.formData.warehouseCode,
            barcodeList: barcodeArr,
          },
          hasLoading: false,
          success: (res) => {
            if (res.success) {
              let newData = [...this.tableData]
              newData.forEach((v) => {
                v.currentQuantity = res.result[v.barcode]
                let num = v.verifiedQuantity - v.currentQuantity
                v.diffQuantityContent = num > 0 ? '+' + num : num
                if (v.currentQuantity > v.verifiedQuantity) {
                  v.inventoryTypeContent = this.$t(`盘亏`)
                } else if (v.currentQuantity < v.verifiedQuantity) {
                  v.inventoryTypeContent = this.$t(`盘盈`)
                } else {
                  v.inventoryTypeContent = this.$t(`正常`)
                }
              })
              this.tableData = [...newData]
            }
          },
        })
      }
    },
    showPriceAdjustment() {
      this.priceAdjustmentData = deepClone(this.tableData).filter(
        (item) => item.inventoryType === 'VARIANCE_IN'
      )
      if (this.priceAdjustmentData.length === 0) {
        return this.$message.warning(this.$t('没有盘盈商品'))
      }
      this.priceAdjustmentVisible = true
    },
    async updateInventorySurplusPrice() {
      this.priceAdjustmentLoading = true
      await http({
        url: api.inventoryChangePrice,
        data: {
          inventoryVerificationId: this.$route.query.id,
          verificationList: this.priceAdjustmentData.map((item) => ({
            inventoryVerificationItemId: item.inventoryVerificationItemId,
            barcode: item.barcode,
            inventoryStatus: item.inventoryStatus,
            unitCode: item.unitCode,
            unitPrice: item.unitPrice,
          })),
        },
      })
      this.$message.success(this.$t('操作成功'))
      this.priceAdjustmentVisible = false
      this.priceAdjustmentLoading = false
      this.initData()
    },
  },
}
</script>

<style lang="scss" scoped>
.form-table-heard:before {
  content: '*';
  color: red;
}

.form-content {
  padding: 20px 5%;
}
.ant-form-item {
  margin-bottom: 0;
}
.search-group {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3px;
  .add {
    position: absolute;
    display: block;
    right: -45px;
  }
}
.goods-list {
  flex-wrap: wrap;
  .goods-item {
    line-height: normal;
    margin-right: 6px;
    margin-bottom: 4px;
    white-space: nowrap;
  }
}
.infoTitle {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fixContent {
  height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
}
.node-modal {
  font-size: 22px;
  text-align: center;
}
.content-overflow {
  width: 100%;
  overflow: auto;
  display: flex;
}
.left-div {
  flex: 1;
}
.right-div {
  background-color: transparent;
  font-size: 12px;
}
.formContentGoods {
  /* padding: 10px 15px; */
  margin-bottom: 16px;
  background-color: white;
  color: #333333;
  font-size: 14px;
}
.mt10 {
  margin-top: 10px;
  display: block;
}
.titleFormBox {
  min-height: 0;
}
.infoTop {
  background-color: white;
  padding: 10px;
}
.infoBottom {
  background-color: white;
  padding: 10px;
  margin-top: 15px;
}
</style>
