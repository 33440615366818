<template>
  <div class="content-wrapper">
    <Spin :spinning="isLoading" style="background: rgba(0, 0, 0, 0)">
      <section class="content clearfix">
        <div>
          <a-row style="display: flex">
            <a-col>
              <a-button type="primary" @click="showMore = true">
                {{ $t(`批量设置虚拟/预警库存`) }}
              </a-button>
            </a-col>
            <a-col class="ml12">
              <a-button type="primary" @click="showModal">
                {{ $t(`批量设置渠道库存`) }}
              </a-button>
            </a-col>
            <a-col class="ml12" @click="showSetModal">
              <a-button type="primary">
                {{ $t(`批量设置单品/组合库存比例`) }}
              </a-button>
            </a-col>
            <a-col class="ml12" @click="showSetSafetyStockModal">
              <a-button type="primary">
                {{ $t(`批量设置安全库存`) }}
              </a-button>
            </a-col>
          </a-row>
          <a-row style="margin-top: 1%" :gutter="16">
            <a-col :span="8">
              <a-input-search
                allowClear
                style="width: 100%"
                :placeholder="$t(`请输入商家编码/商品条码`)"
                v-model.trim="keyWord"
                @search="getSkuStockConfigList"
              ></a-input-search>
            </a-col>
            <a-col :span="3">
              <a-button icon="search" type="primary" @click="searchGetSkuStockConfigList">
                {{ $t(`查询`) }}
              </a-button>
            </a-col>
          </a-row>
          <a-modal
            width="1000px"
            v-model="showMore"
            :title="$t(`批量设置虚拟/预警库存`)"
            @ok="handleOk"
          >
            <a-form-model-item :label="$t(`商品条码包含`)">
              <div class="input-content">
                <a-textarea
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                  :placeholder="$t(`输入商品条码，多个用英文逗号隔开`)"
                  v-model.trim="barcodeList"
                ></a-textarea>
              </div>
            </a-form-model-item>
            <!-- 仓库名称选项 -->
            <a-form-model-item :label="$t(`仓库名称`)">
              <SearchHourse
                :placeholder="$t(`仓库名称`) + '（' + $t(`SingleChoice`) + '）'"
                @selected="selectedWarehouse"
              />
            </a-form-model-item>

            <a-table
              style="margin-top: 2%"
              :columns="innerColumns"
              :data-source="innerList"
              :pagination="showPage"
              :rowKey="(record) => record.barcode"
              bordered
            >
              <!-- 锁定 -->
              <template slot="lock" slot-scope="text, record">
                <a-input v-model="record.lock"></a-input>
              </template>
              <!-- 虚拟 -->
              <template slot="vir" slot-scope="text, record">
                <a-input v-model="record.vir"></a-input>
              </template>
              <!-- 上限 -->
              <template slot="up" slot-scope="text, record">
                <a-input v-model="record.up"></a-input>
              </template>
              <!-- 下限 -->
              <template slot="down" slot-scope="text, record">
                <a-input v-model="record.down"></a-input>
              </template>
            </a-table>
          </a-modal>
        </div>
        <div class="table-content" style="margin-top: 1%">
          <div class="table-body clearfix">
            <a-table
              bordered
              :columns="columns"
              :dataSource="tableDataList"
              :scroll="{ x: 1800, y: tableHeight }"
              :pagination="showPage"
              :row-selection="rowSelection"
              :rowKey="(record, index) => record.barcode + ',' + index"
            >
              <template slot="ItemMerchantCode">{{ $t(`商家编码`) }}/{{ $t(`规格`) }}</template>
              <!-- 商家编码-->
              <span slot="ProductId">
                {{ $t(`商家编码`) }}
              </span>
              <template :slot="item.slot" v-for="(item, index) in tips">
                <div style="display: flex; align-items: center" :key="index">
                  <a-popover>
                    <template slot="content">
                      <span>{{ item.tip }}</span>
                    </template>
                    <span>
                      {{ item.title }}
                      <a-icon style="color: #1890ff" type="question-circle"></a-icon>
                    </span>
                  </a-popover>
                </div>
              </template>
              <template slot="skuCode" slot-scope="text, record">
                <p>
                  <span v-if="record.skuCodeList && record.skuCodeList[0]">
                    {{ record.skuCodeList[0] }}
                  </span>
                </p>
                <p v-if="record.skuCodeList && record.skuCodeList[1]">
                  <a-divider style="width: 150px; margin-left: -16px"></a-divider>
                  {{ record.skuCodeList[1] }}
                </p>
                <p v-if="record.skuCodeList && record.skuCodeList[2]">
                  <a-divider style="width: 150px; margin-left: -16px"></a-divider>
                  {{ record.skuCodeList[2] }}
                </p>
                <p v-if="record.skuCodeList && record.skuCodeList[3]">
                  <a-divider style="width: 150px; margin-left: -16px"></a-divider>
                  {{ record.skuCodeList[3] }}
                </p>
                <p v-if="record.skuCodeList && record.skuCodeList[4]">
                  /{{ record.skuCodeList[4] }}
                </p>
              </template>
              <!-- 仓库 -->
              <span slot="Warehouse">
                {{ $t(`仓库`) }}
              </span>
              <template slot="warehouseName" slot-scope="text, record">
                <div v-if="record.stockWarehouseConfigVOS.length === 0">-</div>
                <div v-else>
                  <p v-for="(item, i) in record.stockWarehouseConfigVOS" :key="i">
                    <a-divider
                      v-if="i > 0"
                      style="width: 135px; margin-left: -16px; margin-top: 44px !important"
                    ></a-divider>
                    {{ record.stockWarehouseConfigVOS[i].warehouseName }}
                  </p>
                </div>
              </template>
              <!-- 组合商品库存占比 -->
              <template slot="groupProportionTitle">
                {{ $t(`单品/组合库存比例`) }}
              </template>
              <span slot="groupProportion" slot-scope="text, record">
                <span v-if="record.groupStockStrategy == 'INDEPENDENT_STOCK'">
                  {{ $t(`独立库存`) }}:{{ record.groupProportion }}
                </span>
                <span v-else>{{ $t(`共享库存`) }}</span>
              </span>
              <!-- 渠道库存 -->
              <span
                v-for="(val, index) in platformList"
                :slot="val.key"
                :key="index"
                slot-scope="text, record"
              >
                <p style="text-align: center">
                  {{ record?.platformStock?.[val.key] }}
                </p>
                <p style="text-align: center">
                  ({{ record?.platformProportion?.[val.key] ?? 0 }}%)
                </p>
              </span>
              <!-- 仓库 可用 -->
              <template slot="AvailableToPromise" slot-scope="text, record">
                <div v-if="record.stockWarehouseConfigVOS.length === 0">-</div>
                <div v-else>
                  <p v-for="(item, i) in record.stockWarehouseConfigVOS" :key="i">
                    <a-divider
                      v-if="i > 0"
                      style="width: 135px; margin-left: -16px; margin-top: 44px !important"
                    ></a-divider>
                    {{ record.stockWarehouseConfigVOS[i].availableToPromise }}
                  </p>
                </div>
              </template>
              <!-- 仓库 残次品 -->
              <template slot="Residuals" slot-scope="text, record">
                <div v-if="record.stockWarehouseConfigVOS.length === 0">-</div>
                <div v-else>
                  <p v-for="(item, i) in record.stockWarehouseConfigVOS" :key="i">
                    <a-divider
                      v-if="i > 0"
                      style="width: 135px; margin-left: -16px; margin-top: 44px !important"
                    ></a-divider>
                    {{ record.stockWarehouseConfigVOS[i].defectiveQuantity }}
                  </p>
                </div>
              </template>
              <!-- 锁定 -->
              <template slot="LockInventory" slot-scope="text, record">
                <a-input v-if="record.editable == true" v-model="record.lockedStock"></a-input>
                <p v-else>{{ record.lockedStock }}</p>
              </template>
              <!-- 虚拟 -->
              <template slot="VirtualInventory" slot-scope="text, record">
                <a-input v-if="record.editable == true" v-model="record.virtualStock"></a-input>
                <p v-else>{{ record.virtualStock }}</p>
              </template>
              <!-- 上限 -->
              <template slot="up" slot-scope="text, record">
                <a-input
                  v-if="record.editable == true"
                  v-model="record.safetyStockUpperLimit"
                ></a-input>
                <span v-else>
                  <p v-if="record.safetyStockUpperLimit == null">-</p>
                  <p v-else>{{ record.safetyStockUpperLimit }}</p>
                </span>
              </template>
              <!-- 下限 -->
              <template slot="down" slot-scope="text, record">
                <a-input
                  v-if="record.editable == true"
                  v-model="record.safetyStockLowerLimit"
                ></a-input>
                <span v-else>
                  <p v-if="record.safetyStockLowerLimit == null">-</p>
                  <p v-else>{{ record.safetyStockLowerLimit }}</p>
                </span>
              </template>
              <!-- 安全库存 -->
              <template slot="safetyQuantity" slot-scope="text, record">
                <span>
                  <p v-if="record.safetyQuantity == null">-</p>
                  <p v-else>{{ record.safetyQuantity }}</p>
                </span>
              </template>
              <template slot="Action" slot-scope="text, record">
                <p v-if="!record.editable" @click="handleEdit(record)">
                  <a href="javascript:;">{{ $t(`编辑`) }}</a>
                </p>
                <p v-else>
                  <a href="javascript:;" @click="saveData(record)">{{ $t(`保存`) }}</a>
                  <a href="javascript:;" style="margin-left: 10px" @click="cancelData(record)">
                    {{ $t(`取消`) }}
                  </a>
                </p>
              </template>
            </a-table>
          </div>
          <div class="table-footer mt6">
            <Pagination
              :pageNo="searchData.pageNo"
              :length="searchData.pageSize"
              :total-count="pageData.totalCount"
              @paginate="paginate"
            />
          </div>
        </div>
      </section>
      <!-- 批量设置渠道库存弹框 -->
      <a-modal
        width="1350px"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :closable="false"
        class="setting"
        :okText="$t(`确认`)"
        :cancelText="$t(`取消`)"
        @ok="handleInventOk"
        @cancel="handleInventCancel"
      >
        <template slot="title">
          {{ $t(`批量设置渠道库存`) }}
          <span style="font-size: 10px !important; color: red">
            {{ $t(`渠道库存比例相加应为100%`) }}
          </span>
        </template>
        <p style="color: black" v-if="selectedRowKeys.length === 0">
          {{ $t(`当前业务组`) + $store.state.userSession.currentParty.partyName }}
        </p>
        <div>
          <span>{{ $t(`渠道库存分配模式`) }}：</span>
          <a-radio-group v-model="platformStockStrategy">
            <a-radio v-for="(item, index) in platformStockStrategyList" :key="index" :value="item">
              {{ $t(`${item}`) }}
            </a-radio>
          </a-radio-group>
        </div>
        <a-table
          style="margin-top: 10px"
          v-if="platformStockStrategy !== 'SHARED_STOCK'"
          :columns="warehouseColumns"
          :data-source="warehouseData"
          bordered
          :pagination="false"
          :rowKey="(record) => record.warehouseCode"
          :row-selection="{
            selectedRowKeys: selectedSetKeys,
            onChange: onSetKeysChange,
          }"
        >
          <!-- 库存名称 -->
          <template slot="warehouse" slot-scope="text, record">
            {{ record.warehouseName }}
          </template>
          <div
            v-for="val in warehouseColumns"
            :slot="val.key"
            :key="val.key"
            slot-scope="text, record"
          >
            <p style="display: flex; align-items: center">
              <a-input
                size="small"
                style="margin: -5px 0; width: 100%"
                suffix="%"
                :value="record.platformProportion[val.key]"
                :precision="0"
                @change="(e) => handleChange(e.target.value, record.warehouseCode, val.key)"
              />
            </p>
          </div>
        </a-table>
      </a-modal>
      <!-- 单品/组合商品比例设置 -->
      <a-modal
        v-model="isShow"
        :confirm-loading="confirmSetLoading"
        class="setting"
        :okText="$t(`确认`)"
        :cancelText="$t(`取消`)"
        @ok="handleSetOk"
        @cancel="handleSetCancel"
      >
        <template slot="title">{{ $t(`批量设置单品/组合库存比例`) }}</template>
        <p style="color: black" v-if="selectedRowKeys.length === 0">
          {{ $t(`当前业务组`) + $store.state.userSession.currentParty.partyName }}
        </p>
        <div>
          <span>{{ $t(`单品/组合商品库存分配模式`) }}：</span>
          <a-radio-group v-model="groupStockStrategy" @change="hanlerChange">
            <a-radio :value="'SHARED_STOCK'">{{ $t(`共享库存`) }}</a-radio>
            <a-radio :value="'INDEPENDENT_STOCK'">{{ $t(`独立库存`) }}</a-radio>
          </a-radio-group>
        </div>
        <div v-show="groupStockStrategy == 'INDEPENDENT_STOCK'">
          <a-form-model-item :label="$t(`单品/组合库存比例`)">
            <a-input
              suffix="%"
              v-model="groupProportion"
              @input="groupProportion = groupProportion.replace(/[^\d]/g, '')"
            />
          </a-form-model-item>
        </div>
      </a-modal>
      <!-- 批量设置安全库存 -->
      <a-modal
        v-model="isSafetyStockShow"
        :confirm-loading="confirmSetLoading"
        class="setting"
        :okText="$t(`确认`)"
        :cancelText="$t(`取消`)"
        @ok="handleSetSafetyStockOk"
        @cancel="handleSetSafetyStockCancel"
      >
        <template slot="title">{{ $t(`批量设置安全库存`) }}</template>
        <p style="color: black" v-if="selectedRowKeys.length === 0">
          {{
            $t(`当前业务组`) +
            '：' +
            $store.state.userSession.currentParty.partyName +
            ',' +
            $t(`请设置当前业务组的安全库存`)
          }}
        </p>
        <div>
          <span>{{ $t(`安全库存比例`) }}</span>
          <span style="color: red">( {{ $t(`注：安全库存=实际库存*安全库存比例`) }} )</span>
        </div>
        <div>
          <a-table
            style="margin-top: 10px"
            :columns="safetyStockColumns"
            :data-source="safetyStockList"
            bordered
            :pagination="false"
            :rowKey="(record) => record.warehouseCode"
            :row-selection="{
              selectedRowKeys: selectedSafetyStockKeys,
              onChange: onSafetyStockKeysKeysChange,
            }"
          >
            <!-- 库存名称 -->
            <template slot="groupProportion" slot-scope="text, record">
              <p style="display: flex; align-items: center; justify-content: center">
                <a-input
                  size="small"
                  style="margin: -5px 0; width: 100%"
                  suffix="%"
                  :value="record.proportion"
                  :precision="0"
                  @change="(e) => handleSafetyStockChange(e.target.value, record.warehouseCode)"
                />
              </p>
            </template>
          </a-table>
        </div>
      </a-modal>
    </Spin>
  </div>
</template>

<script>
import deepClone from '@/common/deepClone'
import { getDateStr } from '@/common/getDateStr'
import http from '@/common/http'
import api from '@/common/api'
import Pagination from '@/component/pagination'
import { Spin } from 'ant-design-vue'
import SearchHourse from '@component/searchHourse'
export default {
  data() {
    return {
      showText: false,
      curApiList: [],
      barcodeList: '',
      formData: [],
      keyWord: '',
      innerColumns: [
        {
          title: this.$t(`仓库库存`),
          children: [
            {
              title: this.$t(`锁定`),
              key: 'lock',
              scopedSlots: { customRender: 'lock' },
            },
            {
              title: this.$t(`虚拟`),
              dataIndex: 'companyName',
              key: 'viawty',
              scopedSlots: { customRender: 'vir' },
            },
          ],
        },
        {
          title: this.$t(`预警库存`),
          children: [
            {
              title: this.$t(`上限`),
              dataIndex: 'companyAddress',
              key: 'up',
              scopedSlots: { customRender: 'up' },
            },
            {
              title: this.$t(`下限`),
              dataIndex: 'companyName',
              key: 'down',
              scopedSlots: { customRender: 'down' },
            },
          ],
        },
      ],
      tableDataList: [],
      platformList: [],
      innerList: [{ lock: '', vir: '', up: '', down: '' }],
      showPage: false,
      showMore: false,
      showWindow: false,
      exportCsv: '',
      pageData: {
        totalCount: '',
      },
      searchData: {
        sort: 'DESC',
        skuId: '',
        skuId1: '',
        skuId2: '',
        orderSn: '',
        statusId: 'INV_STTS_ALL',
        warehouseCode: '',
        startTime: '',
        endTime: '',
        detailType: 'ALL',
        orderType: 'ALL',
        length: 10,
        offset: 0,
        pageNo: 0,
        pageSize: 10,
      },
      orderTypeList: [
        {
          name: `=== ${this.$t(`Sales`)} ===`,
          value: 'SALE',
        },
        {
          name: `=== ${this.$t(`SalesReturns`)} ===`,
          value: 'RETURN',
        },
        {
          name: `=== ${this.$t(`SalesExchange`)} ===`,
          value: 'EXCHANGE',
        },
        {
          name: `=== ${this.$t(`SalesRepost`)} ===`,
          value: 'SUPPLY',
        },
        {
          name: `=== ${this.$t(`TransferOutOfTheLibrary`)} ===`,
          value: 'TRANSFER_OUT',
        },
        {
          name: `=== ${this.$t(`TransferAndStorage`)} ===`,
          value: 'TRANSFER_IN',
        },
        {
          name: `=== ${this.$t(`盘盈`)} ===`,
          value: 'VARIANCE_ADD',
        },
        {
          name: `=== ${this.$t(`盘亏`)} ===`,
          value: 'VARIANCE_MINUS',
        },
        {
          name: `=== ${this.$t(`purchase`)} ===`,
          value: 'PURCHASE',
        },
        {
          name: `=== ${this.$t(`PurchaseReturns`)} ===`,
          value: 'SUPPLIER_RETURN',
        },
      ],
      outOrInList: [
        {
          name: `=== ${this.$t(`OutOfStock`)} ===`,
          value: 'OUT',
        },
        {
          name: `=== ${this.$t(`Warehousing`)} ===`,
          value: 'IN',
        },
      ],
      inventoryStatusList: [
        {
          name: `=== ${this.$t(`库存状态`)} ===`,
          value: 'INV_STTS_ALL',
        },
        {
          name: `=== ${this.$t(`brandNew`)} ===`,
          value: 'INV_STTS_AVAILABLE',
        },
        {
          name: `=== ${this.$t(`secondHand`)} ===`,
          value: 'INV_STTS_USED',
        },
      ],
      orderList: [],
      selectedRowKeys: [],
      // 批量设置渠道库存弹框
      visible: false,
      confirmLoading: false,
      warehouseColumns: [],
      warehouseData: [],
      modalPlatformList: [],
      editable: false,
      temProportion: {}, //修改渠道库存
      temSafetyStockProportion: {},
      modalInfo: {
        modalTitle: this.$t('库存盘点'),
        modalUrl: api.batchSavePurchaseOrder,
        // xlsRequestUrl: api.getBatchCreateGoodsExcel,
        xlsDownloadUrl:
          'https://erp-prod.oss-ap-southeast-1.aliyuncs.com/cloud_bird_oss_gRDnXV93qPU2SDCp/INVENTORY/%E6%89%B9%E9%87%8F%E5%BA%93%E5%AD%98%E7%9B%98%E7%82%B9%E6%A8%A1%E6%9D%BF.xlsx',
      },
      selectedSetKeys: [],
      isLoading: false,
      buBarcode: [],
      buId: '',
      isShow: false,
      isSafetyStockShow: false,
      groupProportion: '',
      groupStockStrategy: 'SHARED_STOCK',
      confirmSetLoading: false,
      warehouseCode: '',
      platformStockStrategy: '',
      platformStockStrategyList: [],
      safetyStockList: [],
      selectedSafetyStockKeys: [],
    }
  },
  mounted() {
    this.buId = this.$store.getters.partyId
    this.getSkuStockConfigList()
    this.$nextTick(() => {
      this.searchData.startTime = getDateStr(-30, true)
      this.searchData.endTime = getDateStr(0, true)
    })
    this.getByBuId()
  },
  components: {
    Pagination,
    Spin,
    SearchHourse,
  },
  methods: {
    hanlerChange(e) {
      this.groupStockStrategy = e.target.value
    },
    mergeCells(text, data, key, index) {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1]?.[key]) {
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      return rowSpan
    },
    handleEdit(record) {
      record.editable = true
    },
    cancelData(e) {
      e.editable = false
      this.getSkuStockConfigList()
    },
    saveData(e) {
      if (Number(e.safetyStockUpperLimit) <= Number(e.safetyStockLowerLimit)) {
        this.$warning({
          title: this.$t(`错误`),
          content: this.$t(`上限必须大于下限`),
        })
        return false
      }
      http({
        url: api.updateSkuStockConfig,
        type: 'post',
        data: {
          skuStockConfigList: [
            {
              barcode: e.barcode,
              lockedStock: e.lockedStock,
              virtualStock: e.virtualStock,
              safetyStockUpperLimit: e.safetyStockUpperLimit,
              safetyStockLowerLimit: e.safetyStockLowerLimit,
              warehouseCode: e.warehouseCode,
            },
          ],
        },
        success: () => {
          this.$success({
            title: this.$t(`保存成功`),
          })
        },
        fail: (res) => {
          this.$message.fail(res.msg)
        },
      })
      e.editable = false
    },
    handleOk() {
      let codeList = this.barcodeList.split(',')
      let tempData = []
      for (let i of codeList) {
        tempData.push({
          barcode: i,
          lockedStock: this.innerList[0].lock,
          virtualStock: this.innerList[0].vir,
          safetyStockUpperLimit: this.innerList[0].up,
          safetyStockLowerLimit: this.innerList[0].down,
          warehouseCode: this.warehouseCode,
        })
      }
      http({
        url: api.updateSkuStockConfig,
        type: 'post',
        data: {
          skuStockConfigList: [...tempData],
        },
        success: () => {
          this.$success({
            title: this.$t(`Success`),
            content: this.$t(`Success`),
          })
          this.getSkuStockConfigList()
          this.showMore = false
        },
        fail: (res) => {
          this.$warning({
            title: this.$t(`Warning`),
            content: res.msg,
          })
        },
      })
    },
    async getSkuStockConfigList() {
      this.selectedRowKeys = []
      this.tableDataList = []
      await http({
        url: api.getSkuStockConfigList,
        type: 'post',
        data: {
          keys: this.keyWord,
          pageNo: this.searchData.pageNo,
          pageSize: this.searchData.pageSize,
          buId: this.buId,
        },
        hasLoading: true,
        success: (res) => {
          if (!res.result.skuStockConfigList) return
          for (let item of res.result.skuStockConfigList) {
            item.editable = false
            this.tableDataList.push(item)
          }
          this.searchData.pageNo = res.result.page_no
          this.pageData.totalCount = res.result.total
          if (this.tableDataList.length == 0) {
            this.$warning(this.$t(`暂无数据`))
          }
          let channel = res.result.platformList //渠道库存
          this.platformList = [] //清空表头
          if (channel && channel.length) {
            channel.forEach((v) => {
              this.platformList.push({
                title: v.name,
                dataIndex: v.code,
                key: v.code,
                width: 80,
                slots: { title: v.code },
                scopedSlots: { customRender: v.code },
              })
            })
          }
          console.log(this.tableDataList)
        },
      })
    },
    searchGetSkuStockConfigList() {
      this.searchData.pageNo = 1
      this.getSkuStockConfigList()
    },
    selectedTime(data) {
      this.searchData.startTime = data.start_time
      this.searchData.endTime = data.end_time
    },
    paginate(data) {
      this.searchData.pageNo = data.pageNo
      this.searchData.offset = data.offset
      this.searchData.length = data.length
      this.searchData.pageSize = data.pageSize
      this.getSkuStockConfigList()
    },
    selectedSku(data) {
      if ($.isEmptyObject(data)) {
        this.searchData.skuId1 = ''
      } else {
        this.searchData.skuId1 = data.skuId
      }
    },
    // 点击出现！的文本解释
    showTextFn() {
      this.showText = !this.showText
    },
    // 多选框选择
    onSelectChange(selectedRowKeys) {
      this.tempRowKeys = []
      selectedRowKeys.forEach((v) => {
        this.tempRowKeys.push(v.split(',')[0])
      })
      this.selectedRowKeys = selectedRowKeys
    },
    // 批量设置渠道库存弹框相关逻辑
    async showModal() {
      let data = {}
      if (this.selectedRowKeys.length) {
        data.barcodeList = [...new Set(this.tempRowKeys)]
      } else {
        data.barcodeList = this.buBarcode
        data.buId = this.buId
      }
      await http({
        url: api.skuPlatformStockConfigList,
        type: 'post',
        data: data,
        hasLoading: true,
        success: (res) => {
          if (res.code === 1000000) {
            this.visible = true
          }
          this.warehouseColumns = [
            {
              title: this.$t(`仓库`),
              dataIndex: 'warehouse',
              width: '200px',
              scopedSlots: { customRender: 'warehouse' },
            },
          ]
          let platformList = res.result.platformList
          platformList.forEach((v) => {
            this.warehouseColumns.push({
              title: v.name,
              dataIndex: v.code,
              key: v.code,
              scopedSlots: { customRender: v.code },
            })
          })
          this.modalPlatformList = platformList
          this.warehouseData = res.result.stockWarehouseConfigVOS
          this.platformStockStrategy = res.result.platformStockStrategy
          this.platformStockStrategyList = res.result.platformStockStrategyList
        },
      })
    },
    // 确定
    async handleInventOk() {
      let data = {}
      if (this.platformStockStrategy === 'SHARED_STOCK') {
        data = {
          barcodeList: this.selectedRowKeys.length
            ? [...new Set(this.tempRowKeys)]
            : this.buBarcode,
          platformStockStrategy: this.platformStockStrategy,
          buId: this.selectedRowKeys.length ? null : this.buId,
        }
      } else {
        if (this.selectedSetKeys.length === 0) {
          this.$message.warning(this.$t(`请选择要调整的仓库`))
          return
        }
        let temProportion = {}
        let platformList = this.modalPlatformList.map((v) => v.code)
        this.warehouseData.forEach((item) => {
          this.selectedSetKeys.forEach((v) => {
            if (item.warehouseCode === v) {
              let obj = deepClone(item.platformProportion, 1)
              platformList.forEach((key) => {
                obj[key] = +item.platformProportion[key] || 0
              })
              temProportion[item.warehouseCode] = obj
            }
          })
        })
        data = {
          barcodeList: this.selectedRowKeys.length
            ? [...new Set(this.tempRowKeys)]
            : this.buBarcode,
          warehousePlatformMap: { ...temProportion },
          platformStockStrategy: this.platformStockStrategy,
          buId: this.selectedRowKeys.length ? null : this.buId,
        }
      }
      this.confirmLoading = true
      try {
        await http({
          url: api.updatePlatformStockConfig,
          type: 'post',
          data: data,
          hasLoading: true,
          success: (res) => {
            this.selectedSetKeys = []
            if (res.code === 1000000) {
              setTimeout(() => {
                this.$success({
                  title: this.$t(`Success`),
                  content: this.$t(`修改成功`),
                })
                this.visible = false
                this.getSkuStockConfigList()
                this.selectedSetKeys = []
              }, 500)
            }
          },
        })
        this.confirmLoading = false
      } catch (err) {
        console.log(err)
        this.confirmLoading = false
      }
    },
    // 取消
    handleInventCancel() {
      this.visible = false
      this.selectedSetKeys = []
    },
    //单品/组合商品库存比例设置
    async showSetModal() {
      let data = {}
      if (this.selectedRowKeys.length) {
        data.barcodeList = [...new Set(this.tempRowKeys)]
      } else {
        data.barcodeList = this.buBarcode
      }
      await http({
        url: api.queryGroupProportionConfig,
        type: 'post',
        data: data,
        hasLoading: true,
        success: (res) => {
          if (res.code === 1000000) {
            this.isShow = !this.isShow
          }
          this.groupProportion = res.result.groupProportion
        },
      })
    },
    //批量设置安全库存
    async showSetSafetyStockModal() {
      let data = {}
      if (this.selectedRowKeys.length) {
        data.barcodeList = [...new Set(this.tempRowKeys)]
      } else {
        data.barcodeList = this.buBarcode
      }
      await http({
        url: api.safetyStockTransformConfigList,
        type: 'post',
        data: data,
        hasLoading: true,
        success: (res) => {
          if (res.code === 1000000) {
            this.isSafetyStockShow = !this.isSafetyStockShow
          }
          this.warehouseColumns = [
            {
              title: this.$t(`仓库`),
              dataIndex: 'warehouse',
              width: '100px',
              scopedSlots: { customRender: 'warehouse' },
            },
          ]
          this.safetyStockList = res.result.warehouseProportion
        },
      })
    },
    // 确定
    async handleSetOk() {
      this.confirmSetLoading = true
      try {
        await http({
          url: api.updateGroupProportionConfig,
          type: 'post',
          hasLoading: true,
          data: {
            barcodeList: this.selectedRowKeys.length
              ? [...new Set(this.tempRowKeys)]
              : this.buBarcode,
            groupProportion: this.groupProportion,
            groupStockStrategy: this.groupStockStrategy,
          },
          success: (res) => {
            this.selectedSetKeys = []
            if (res.code === 1000000) {
              setTimeout(() => {
                this.$success({
                  title: this.$t(`Success`),
                  content: this.$t(`修改成功`),
                })
                this.isShow = false
                this.getSkuStockConfigList()
                this.selectedSetKeys = []
                this.groupProportion = ''
              }, 500)
            }
          },
        })
        this.confirmSetLoading = false
      } catch (err) {
        console.log(err)
        this.confirmSetLoading = false
      }
    },
    // 确定
    async handleSetSafetyStockOk() {
      this.confirmSetLoading = true
      let temSafetyStockProportion = {}
      if (this.selectedSafetyStockKeys.length) {
        this.selectedSafetyStockKeys.forEach((item) => {
          temSafetyStockProportion[item] = this.temSafetyStockProportion[item] || 0
        })
      } else {
        this.$warning(this.$t(`请选择仓库`))
        this.confirmSetLoading = false
        return
      }
      try {
        await http({
          url: api.createSafetyStockTransform,
          type: 'post',
          hasLoading: true,
          data: {
            barcodeList: this.selectedRowKeys.length
              ? [...new Set(this.tempRowKeys)]
              : this.buBarcode,
            warehouseProportion: temSafetyStockProportion,
          },
          success: (res) => {
            if (res.code === 1000000) {
              setTimeout(() => {
                this.$success({
                  title: this.$t(`Success`),
                  content: this.$t(`修改成功`),
                })
                this.isSafetyStockShow = false
                this.getSkuStockConfigList()
                this.selectedSafetyStockKeys = []
              }, 500)
            }
          },
        })
        this.confirmSetLoading = false
      } catch (err) {
        console.log(err)
        this.confirmSetLoading = false
      }
    },
    // 取消
    handleSetCancel() {
      this.isShow = false
      this.groupProportion = ''
    },
    handleSetSafetyStockCancel() {
      this.isSafetyStockShow = false
      this.groupProportion = ''
    },
    handleChange(value, key, column) {
      value = value.replace(/[^\d]+/g, '')
      const newData = [...this.warehouseData]
      const target = newData.find((item) => key === item.warehouseCode)
      if (target) {
        target.platformProportion[column] = value
        this.warehouseData = [...newData]
        let temProportion = {}
        newData.forEach((item) => {
          temProportion[item.warehouseCode] = { ...item.platformProportion }
        })
        this.temProportion = temProportion
        if (this.selectedSetKeys.findIndex((item) => item === key) === -1) {
          this.selectedSetKeys.push(key)
        }
      }
    },
    handleSafetyStockChange(value, key) {
      value = value.replace(/[^\d]+/g, '')
      const newData = [...this.safetyStockList]
      const target = newData.find((item) => key === item.warehouseCode)
      if (target) {
        target.proportion = value
        let temProportion = {}
        newData.forEach((item) => {
          temProportion[item.warehouseCode] = item.proportion
        })
        this.temSafetyStockProportion = temProportion
      }
    },
    onSetKeysChange(selectedSetKeys) {
      this.selectedSetKeys = selectedSetKeys
    },
    onSafetyStockKeysKeysChange(selectedSetKeys) {
      this.selectedSafetyStockKeys = selectedSetKeys
    },
    selectedWarehouse(data) {
      this.warehouseCode = data.warehouseCode
    },
    getByBuId() {
      http({
        url: api.barcodeListByBuId,
        type: 'post',
        data: {},
        success: (res) => {
          this.buBarcode = res.result
        },
      })
    },
  },

  computed: {
    tips() {
      return [
        {
          slot: 'ActualInventory',
          title: this.$t('实际'),
          tip: this.$t(`仓库内现有的库存数量`),
        },
        {
          slot: 'AvailableInventory',
          title: this.$t('可用'),
          tip: this.$t(`当前可预定的库存数量`),
        },
        {
          slot: 'DefectiveInventory',
          title: this.$t('残次品'),
          tip: this.$t(`仓库内现有的残次品库存数量`),
        },
        {
          slot: 'VirtualInventoryTitle',
          title: this.$t('虚拟'),
          tip: this.$t(`可用库存的基础上，增加的虚拟部分，使满足销售和订单下推`),
        },
        {
          slot: 'LockInventory',
          title: this.$t(`锁定`),
          tip: this.$t(`锁定库存：可用库存中，设置禁止销售的部分`),
        },
        {
          slot: 'channelInventoryTitle',
          title: this.$t(`渠道库存`),
          tip: this.$t(`分配给不同渠道的库存，计算口径为可用库存*渠道库存百分比`),
        },
        {
          slot: 'safetyQuantityTitle',
          title: this.$t(`安全库存`),
          tip: this.$t(`安全库存：可用库存中，设置不允许预定的部分`),
        },
      ]
    },
    columns() {
      return [
        {
          title: this.$t(`商品信息`),
          key: 'ProductInfo',
          width: 135,
          customRender: (text, record, index) => {
            var child = this.$createElement('div', [
              this.$createElement('img', {
                domProps: {
                  src: record.skuImage,
                  width: 60,
                  height: 60,
                },
              }),
              this.$createElement('p', {
                domProps: {
                  innerHTML: record.skuName,
                },
              }),
            ])
            const obj = {
              children: child,
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['barcode'],
              this.tableDataList,
              'barcode',
              index
            )
            return obj
          },
        },
        {
          title: this.$t(`商品条码`),
          key: 'barcode',
          dataIndex: 'barcode',
          width: 135,
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['barcode'],
              this.tableDataList,
              'barcode',
              index
            )
            return obj
          },
        },
        {
          width: 150,
          key: 'skuCode',
          // fixed: "left",
          slots: { title: 'ProductId' },
          customRender: (text, record, index) => {
            var child = this.$createElement('div', [
              this.$createElement('span', {
                domProps: {
                  innerHTML: record.skuCodeList.join(','),
                },
              }),
            ])
            const obj = {
              children: child,
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['barcode'],
              this.tableDataList,
              'barcode',
              index
            )
            return obj
          },
        },
        {
          width: 150,
          title: this.$t(`规格值`),
          dataIndex: 'skuStyleList',
          customRender: (text) => text.join(','),
        },
        {
          width: 150,
          key: 'groupProportion',
          dataIndex: 'groupProportion',
          slots: { title: 'groupProportionTitle' },
          scopedSlots: { customRender: 'groupProportion' },
        },
        {
          width: 135,
          key: 'warehouseName',
          dataIndex: 'warehouseName',
          slots: { title: 'Warehouse' },
        },
        {
          slots: { title: 'safetyQuantityTitle' },
          key: 'safetyQuantity',
          scopedSlots: { customRender: 'safetyQuantity' },
          width: 100,
        },
        {
          slots: { title: 'channelInventoryTitle' },
          children: Array.from(this.platformList),
        },
        {
          title: this.$t(`仓库库存`),
          children: [
            {
              slots: { title: 'ActualInventory' },
              key: 'quantityOnHand',
              dataIndex: 'quantityOnHand',
              width: 100,
            },
            {
              slots: { title: 'AvailableInventory' },
              key: 'availableToPromise',
              dataIndex: 'availableToPromise',
              width: 100,
            },
            {
              slots: { title: 'DefectiveInventory' },
              key: 'defectiveQuantity',
              dataIndex: 'defectiveQuantity',
              width: 100,
            },
            {
              slots: { title: 'LockInventory' },
              key: 'LockInventory',
              scopedSlots: { customRender: 'LockInventory' },
              width: 100,
            },
            {
              slots: { title: 'VirtualInventoryTitle' },
              key: 'VirtualInventory',
              scopedSlots: { customRender: 'VirtualInventory' },
              width: 100,
            },
          ],
        },
        {
          title: this.$t(`预警库存`),
          children: [
            {
              title: this.$t(`上限`),
              key: 'up',
              width: 100,
              scopedSlots: { customRender: 'up' },
            },
            {
              title: this.$t(`下限`),
              key: 'down',
              scopedSlots: { customRender: 'down' },
              width: 100,
            },
          ],
        },
        {
          title: this.$t(`操作`),
          key: 'Action',
          scopedSlots: { customRender: 'Action' },
          width: 120,
        },
      ]
    },
    safetyStockColumns() {
      return [
        {
          width: 150,
          title: this.$t(`仓库`),
          key: 'warehouseName',
          dataIndex: 'warehouseName',
          align: 'center',
        },
        {
          width: 150,
          title: this.$t(`安全库存`),
          key: 'proportion',
          dataIndex: 'proportion',
          align: 'center',
          scopedSlots: { customRender: 'groupProportion' },
        },
      ]
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
      }
    },
    tableHeight() {
      return (window.innerHeight - 340) * this.$store.state.rate
    },
  },
}
</script>
<style lang="scss" scoped>
.headerMsg {
  display: flex;
  /* align-items: center; */
}
.input-content {
  position: relative;
  .textarea {
    height: 90px;
    padding-right: 45px;
  }
  .num {
    position: absolute;
    right: 22px;
    bottom: 0px;
    color: #999;
  }
}
.buttons {
  margin-bottom: 8px;
}
.table {
  margin-bottom: 0px;
}
.col-md-12 {
  padding-left: 0px;
  padding-right: 10px;
}
.table td {
  vertical-align: middle;
  padding-right: 2px;
  padding-left: 2px;
  text-align: center;
}
.table th {
  padding-left: 2px;
  padding-right: 2px;
}
label.control-label {
  padding-right: 0px;
  padding-left: 0px;
}
.col-md-5,
.col-md-2,
.col-md-3 {
  padding-right: 1px;
  padding-left: 6px;
}
.confirm-icon {
  color: #78bf2a;
}
.cancel-icon,
.delete-icon {
  color: #f86767;
}
.not-receipt {
  background-color: #e4eff7;
}
::v-deep .ant-table-thead > tr > th {
  padding: 5px;
  text-align: center;
}
::v-deep .ant-table-tbody > td {
  padding: 5px;
  text-align: center;
}
.setting::v-deep .ant-modal-title span {
  font-size: 12px;
  margin-left: 5px;
}
::v-deep .ant-table-body {
  //样式穿透
  overflow-x: auto !important;
}
.upload-content {
  padding: 20px;
}
.mt10 {
  margin-top: 10px;
  display: block;
  color: #40a9ff;
}
.display_none {
  display: none;
}
.matgin_left {
  margin-left: 15px;
}
</style>
<style>
.ant-popover-arrow {
  border-color: rgba(0, 0, 0, 0.7) !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}
.ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.7);
}
.ant-popover-inner-content span {
  color: #fff;
  font-weight: bold;
  white-space: pre-line;
}
</style>
